import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ImmobilierIllustration from "../images/maison-interieur.jpg";
import LocationIllustration from "../images/location.jpg";
import RestaurantIllustration from "../images/restaurant.jpg";
import MuseumIllustration from "../images/museum.jpg";
import GiteIllustration from "../images/gite.jpg";
import { Link } from "gatsby";


function VisitePage() {
  return (
    <Layout>
      <SEO
        title="Visites virtuelles 3D Matterport"
      />
<div className="mx-auto max-w-screen-xl">

  <div className=" pt-10">
    <h1>Visites vituelles 3D Matterport</h1>
    <div className="text-center max-w-xl mx-auto">
      <p>Que vous vouliez mettre en valeur une maison ou un appartement à vendre, votre restaurant, votre gîte ou un espace culturel, la technologie 3D Matterport est faite pour vous.</p>
    </div>
  </div>
  <div className="relative bg-gray-800 mt-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
    <div className="h-56 bg-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
      <img className="w-full h-full object-cover" src={ImmobilierIllustration} alt="Immobilier" />
    </div>
    <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div className="md:ml-auto md:w-1/2 md:pl-10">
        <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
          Immobilier
        </h2>
        <p className="mt-3 text-lg leading-7 text-gray-300">
          Vendez votre maison ou votre appartement beaucoup plus vite en qualifiant plus efficacement vos éventuels acheteurs.<br/>Donnez la possibilité à vos futurs locataires de visualiser à distance l’intérieur de vos espaces afin qu’ils se fassent une idée très précise des lieux.
        </p>
        <div className="mt-8">
          <div className="inline-flex rounded-md shadow">
            <Link to="/visites-virtuelles/immobilier" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              En savoir plus
              <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clipRule="evenodd"/>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="relative bg-gray-800 mt-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
    <div className="h-56 bg-red-600 sm:h-72 md:absolute md:right-0 md:h-full md:w-1/2">
      <img className="w-full h-full object-cover" src={RestaurantIllustration} alt="Restaurants et commerces" />
    </div>
    <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div className="md:mr-auto md:w-1/2 md:pr-10">
      
        <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
        Restaurants et commerces
        </h2>
        <p className="mt-3 text-lg leading-7 text-gray-300">
          Mettez en valeur votre restaurant ou votre commerce. Soyez présent sur les réseaux sociaux en y intégrant des minis vidéos et des panoramiques 360°
        </p>
        <div className="mt-8">
          <div className="inline-flex rounded-md shadow">
            <Link to="/visites-virtuelles/restaurants" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              En savoir plus
              <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clipRule="evenodd"/>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>    
  </div>

<div className="relative bg-gray-800 mt-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
  <div className="h-56 bg-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
    <img className="w-full h-full object-cover" src={LocationIllustration} alt="Locations saisonnières / colocations" />
  </div>
  <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
      Locations saisonnières
      </h2>
      <p className="mt-3 text-lg leading-7 text-gray-300">
      Que ce soit pour la location saisonnière ou la location d’espaces de travail, donnez la possibilité à vos prospects de visualiser à distance l’intérieur de vos espaces afin qu’ils se fassent une idée très précise des lieux.<br/>
Grâce à cet outil immersif augmentez le nombre de prospects qualifiés et louez plus rapidement.
      </p>
      <div className="mt-8">
        <div className="inline-flex rounded-md shadow">
          <Link to="/visites-virtuelles/locations-saisonnieres" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            En savoir plus
            <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clipRule="evenodd"/>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  </div>    
</div>

<div className="relative bg-gray-800 mt-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
  <div className="h-56 bg-red-600 sm:h-72 md:absolute md:right-0 md:h-full md:w-1/2">
    <img className="w-full h-full object-cover" src={MuseumIllustration} alt="Espaces culturels" />
  </div>
  <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <div className="md:mr-auto md:w-1/2 md:pr-10">
      <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
      Espaces culturels
      </h2>
      <p className="mt-3 text-lg leading-7 text-gray-300">
        Visiter un musée ou une gallerie sans se déplacer de chez soi est une nouvelle tendance. La visite virtuelle 3D Matterport est un réel atout et un plus indéniable pour mettre en valeur vos espaces culturels.
      </p>
      <div className="mt-8">
        <div className="inline-flex rounded-md shadow">
          <Link to="/visites-virtuelles/espaces-culturels" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            En savoir plus
            <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clipRule="evenodd"/>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  </div>    
</div>


<div className="relative bg-gray-800 mt-4 mb-8 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
  <div className="h-56 bg-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
    <img className="w-full h-full object-cover" src={GiteIllustration} alt="Hôtellerie / gites" />
  </div>
  <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
    
      <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
        Hôtellerie / gites
      </h2>
      <p className="mt-3 text-lg leading-7 text-gray-300">
        Mettez en valeur votre hôtel ou votre gite en proposant à vos futurs clients une immersion via la visite virtuelle 3D Matterport.<br/>Créez de l'émotion et remplissez vos chambres!
      </p>
      <div className="mt-8">
        <div className="inline-flex rounded-md shadow">
          <Link to="/visites-virtuelles/hotels-gites" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            En savoir plus
            <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clipRule="evenodd"/>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  </div>    
</div>

<div className="bg-white">
  <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <p className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
      Votre activité n&apos;est pas listée ? Contactez nous!
    </p>
    <p className="text-xl leading-9 font-extrabold tracking-tight text-gray-500">
      La technologie Matterport 3D est un outil puissant pour booster votre activité.
    </p>
    <div className="mt-8 flex justify-center flex-wrap">
      <div className="inline-flex rounded-md shadow mb-1">
        <Link to="/contact" title="Contactez-nous" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
        <svg className="h-6 w-6 pr-1 inline-block" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"/>
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"/>
        </svg> Contactez-nous
        </Link>
      </div>
      <div className="sm:ml-3 inline-flex mb-1">
        <a href="tel:0033582951635" title="Appelez nous" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-700 bg-red-100 hover:text-red-600 hover:bg-red-50 focus:outline-none focus:shadow-outline focus:border-red-300 transition duration-150 ease-in-out">
        <svg className="h-6 w-6 pr-1 inline-block" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
        </svg>
          05 82 95 16 35
        </a>
      </div>
    </div>
  </div>
</div>

</div>
    </Layout>
  );
}

export default VisitePage;
